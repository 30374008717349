/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceCellDTO } from '../models/InvoiceCellDTO';
import type { SettingsDTO } from '../models/SettingsDTO';
import type { SettingsInvoiceLines } from '../models/SettingsInvoiceLines';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettingsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param dataKey Returns organization data for the provided query key
   * @returns SettingsDTO Org data for the provided query key
   * @throws ApiError
   */
  public getSettings(
    dataKey?: string,
  ): CancelablePromise<SettingsDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/settings',
      query: {
        'data_key': dataKey,
      },
    });
  }

  /**
   * @param requestBody Updated organizational data
   * @returns SettingsDTO
   * @throws ApiError
   */
  public setSettings(
    requestBody: SettingsDTO,
  ): CancelablePromise<SettingsDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/settings',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SettingsInvoiceLines
   * @throws ApiError
   */
  public getProjectInvoiceLines(): CancelablePromise<SettingsInvoiceLines> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/settings/vendor-statements',
    });
  }

  /**
   * @param projectUuid
   * @param requestBody Update setting invoice line tags
   * @returns InvoiceCellDTO
   * @throws ApiError
   */
  public updateSettingInvoiceLines(
    projectUuid: string,
    requestBody: Array<InvoiceCellDTO>,
  ): CancelablePromise<Array<InvoiceCellDTO>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/settings/vendor-statements/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
