/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloneModelDTO } from '../models/CloneModelDTO';
import type { ModelCountResponse } from '../models/ModelCountResponse';
import type { ModelDTO } from '../models/ModelDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModelsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns ModelCountResponse Returns count of the created models
   * @throws ApiError
   */
  public cloneModel(
    requestBody: CloneModelDTO,
  ): CancelablePromise<ModelCountResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/models/model/clone',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param modelUuid The UUID of the model to get
   * @returns ModelDTO Returns the requested model
   * @throws ApiError
   */
  public deleteModel(
    modelUuid: string,
  ): CancelablePromise<ModelDTO> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/models/{model_uuid}',
      path: {
        'model_uuid': modelUuid,
      },
    });
  }

}
