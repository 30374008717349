import { lazy } from "react";

export const lazyWithReload: typeof lazy = importer => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: any) {
      window.location.href = window.location.href;
    }
  };
  return lazy(retryImport);
};
