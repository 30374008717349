/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReconciliationCacheUpdateDTO } from '../models/ReconciliationCacheUpdateDTO';
import type { ReconciliationCacheUpdateDurationDTO } from '../models/ReconciliationCacheUpdateDurationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DataMatchAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any
   * @throws ApiError
   */
  public getLastReconciliationRun(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match/reconciliation-runs/last',
    });
  }

  /**
   * @returns ReconciliationCacheUpdateDurationDTO
   * @throws ApiError
   */
  public getReconciliationCacheRefreshDuration(): CancelablePromise<ReconciliationCacheUpdateDurationDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match/reconciliation-cache-updates/avg-duration',
    });
  }

  /**
   * @returns ReconciliationCacheUpdateDTO
   * @throws ApiError
   */
  public getActiveReconciliationCacheUpdates(): CancelablePromise<Array<ReconciliationCacheUpdateDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match/reconciliation-cache-updates/active',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public getSettings(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match/settings',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public createValidationConfig(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/settings/create-validation-config',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public editValidationConfigProjects(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/data-match/settings/validation-config/{validation_config_uuid}/projects',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public editValidationConfig(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/data-match/settings/validation-config/{validation_config_uuid}',
    });
  }

  /**
   * @param validationConfigUuid
   * @returns any
   * @throws ApiError
   */
  public deleteValidationConfig(
    validationConfigUuid: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/data-match/settings/validation-config/{validation_config_uuid}',
      path: {
        'validation_config_uuid': validationConfigUuid,
      },
    });
  }

  /**
   * @param periodStart
   * @param periodEnd
   * @param id
   * @param projects
   * @param uploads
   * @param pspStatus
   * @param pboStatus
   * @param pboMethod
   * @returns any
   * @throws ApiError
   */
  public getMatchingSummary(
    periodStart: string,
    periodEnd: string,
    id: string,
    projects: Array<string>,
    uploads: Array<string>,
    pspStatus: Array<string>,
    pboStatus: Array<string>,
    pboMethod: Array<string>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match/summary',
      query: {
        'period_start': periodStart,
        'period_end': periodEnd,
        'id': id,
        'projects': projects,
        'uploads': uploads,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
      },
    });
  }

  /**
   * @param periodStart
   * @param periodEnd
   * @param offset
   * @param limit
   * @param filter
   * @param id
   * @param projects
   * @param uploads
   * @param pspStatus
   * @param pboStatus
   * @param pboMethod
   * @returns any
   * @throws ApiError
   */
  public getMatchTransactions(
    periodStart: string,
    periodEnd: string,
    offset: number,
    limit: number,
    filter: string,
    id: string,
    projects: Array<string>,
    uploads: Array<string>,
    pspStatus: Array<string>,
    pboStatus: Array<string>,
    pboMethod: Array<string>,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match',
      query: {
        'period_start': periodStart,
        'period_end': periodEnd,
        'offset': offset,
        'limit': limit,
        'filter': filter,
        'id': id,
        'projects': projects,
        'uploads': uploads,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
      },
    });
  }

  /**
   * @param periodStart
   * @param periodEnd
   * @param filter
   * @param id
   * @param type
   * @param projects
   * @param uploads
   * @param pspStatus
   * @param pboStatus
   * @param pboMethod
   * @returns any
   * @throws ApiError
   */
  public getMatchTransactionsDownload(
    periodStart: string,
    periodEnd: string,
    filter: string,
    id: string,
    type: string,
    projects: Array<string>,
    uploads: Array<string>,
    pspStatus: Array<string>,
    pboStatus: Array<string>,
    pboMethod: Array<string>,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match/download',
      query: {
        'period_start': periodStart,
        'period_end': periodEnd,
        'filter': filter,
        'id': id,
        'type': type,
        'projects': projects,
        'uploads': uploads,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public upsertResolves(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/resolve',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public deleteResolve(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/unresolve',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public requestMatchingUpload(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/request',
    });
  }

  /**
   * @param connectionUuid
   * @returns any
   * @throws ApiError
   */
  public requestMatchingSync(
    connectionUuid: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/request/{connection_uuid}',
      path: {
        'connection_uuid': connectionUuid,
      },
    });
  }

  /**
   * @param projects
   * @param uploads
   * @param periodStart
   * @param periodEnd
   * @param id
   * @param pspStatus
   * @param pboStatus
   * @param pboMethod
   * @param filter
   * @returns any
   * @throws ApiError
   */
  public getPossibleFilters(
    projects?: Array<string>,
    uploads?: string,
    periodStart?: string,
    periodEnd?: string,
    id?: string,
    pspStatus?: Array<string>,
    pboStatus?: Array<string>,
    pboMethod?: Array<string>,
    filter?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/data-match/filters',
      query: {
        'projects': projects,
        'uploads': uploads,
        'period_start': periodStart,
        'period_end': periodEnd,
        'id': id,
        'psp_status': pspStatus,
        'pbo_status': pboStatus,
        'pbo_method': pboMethod,
        'filter': filter,
      },
    });
  }

}
