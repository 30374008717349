/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileExtractionConfigsDTO } from './FileExtractionConfigsDTO';
import type { FileExtractionRunDTO } from './FileExtractionRunDTO';
import type { FileUploadProjectLinksDto } from './FileUploadProjectLinksDto';
import type { ProjectDTO } from './ProjectDTO';

export type DeleteFileUploadDTO = {
  uuid: string;
  name: string;
  type: DeleteFileUploadDTO.type | null;
  start_date: string | null;
  end_date: string | null;
  project_uuid?: string | null;
  created_at: string;
  updated_at?: string | null;
  status: string;
  path?: string;
  last_file_extraction_run?: FileExtractionRunDTO | null;
  file_extraction_runs?: Array<FileExtractionRunDTO> | null;
  projects?: Array<ProjectDTO>;
  error?: string;
  file_upload_project_links?: Array<FileUploadProjectLinksDto>;
  file_extraction_config_uuid?: string | null;
  file_extraction_configs?: Array<FileExtractionConfigsDTO>;
  count: number;
};

export namespace DeleteFileUploadDTO {

  export enum type {
    PSP = 'psp',
    INVOICE = 'invoice',
    PRICING_TERM = 'pricingTerm',
    INTEGRATION = 'integration',
  }


}

