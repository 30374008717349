/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SettlementDTO = {
  calculation_uuid: string;
  created_at: string;
  finished_at: string;
  model_uuid: string;
  period_end: string;
  period_start: string;
  project_uuid: string;
  projects: {
    project_name?: string;
  };
  result_file: string;
  source_type: SettlementDTO.source_type | null;
  status: SettlementDTO.status | null;
  template: string;
  updated_at?: string;
  uuid: string;
};

export namespace SettlementDTO {

  export enum source_type {
    USER = 'user',
    SYSTEM = 'system',
  }

  export enum status {
    STARTED = 'started',
    FINISHED = 'finished',
    FAILED = 'failed',
  }


}

