import { createTheme } from "@mui/material/styles";
import "typeface-roboto";
import { PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteColor {
    text?: string;
    textSecondary?: string;
    textDisabled?: string;
  }
  interface SimplePaletteColorOptions {
    text?: string;
    textSecondary?: string;
    textDisabled?: string;
  }

  interface CustomPalette {
    onDark: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    onDark: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    onDark: true;
  }
}

export const colors = {
  gray: "#9DA1A7",
  lightGray: "#cad7e6",
  textMain: "#424242",
  textLight: "#A6A6A6",
  transactionsHandleColor: "#707799",
  transactionsHandleColorSelected: "#626B99",
  costElementsHandleColor: "#3B917A",
  costElementsHandleColorSelected: "#179171",
  allElementsHandleColor: "#616161",
  allElementsHandleColorSelected: "#616161",
  hl70: "rgba(255, 255, 255, 0.7)",
  hl20: "rgba(255, 255, 255, 0.20)",
  hl10: "rgba(255, 255, 255, 0.10)",
  redAlert: "#B23842",
};

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = mainColor => augmentColor({ color: { main: mainColor } });
export const theme = createTheme({
  direction: "ltr",
  palette: {
    mode: "light",
    primary: {
      main: "#333958",
      light: "#606788",
      dark: "#020A27",

      text: "#ffffff",
      textSecondary: "#ffffffb3",
      textDisabled: "#ffffff80",
    },
    secondary: {
      main: "#1976D2",
      dark: "#1565c0",
      light: "#42a5f5",
      contrastText: "#ffffff",
    },
    onDark: createColor("#ffffff"),
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica,Arial", "sans-serif"].join(","),
    button: {
      fontFamily: "ABCWhyte",
      textTransform: "none",
      whiteSpace: "nowrap",
      minWidth: "auto",
    },
    h1: {
      fontFamily: "ABCWhyte",
    },
    h2: {
      fontFamily: "ABCWhyte",
    },
    h3: {
      fontFamily: "ABCWhyte",
    },
    h4: {
      fontFamily: "ABCWhyte",
    },
    h5: {
      fontFamily: "ABCWhyte",
    },
    h6: {
      fontFamily: "ABCWhyte",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "32px",
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
    },
    body1: {
      fontSize: "14px",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.variant === "contained") {
            if (ownerState.color === "onDark") {
              return {
                backgroundColor: "#1D42C9",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#142e90",
                },
                "&.Mui-disabled": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "rgba(255, 255, 255, 0.44)",
                },
              };
            }
            return {
              backgroundColor: "#1D42C9",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#142e90",
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                color: "rgba(0, 0, 0, 0.35)",
              },
            };
          }
          if (ownerState.variant === "outlined") {
            if (ownerState.color === "onDark") {
              return {
                color: "#FFFFFF",
                backgroundColor: "#141436",
                border: "1px solid rgba(255, 255, 255, 0.4)",
                "&:hover": {
                  border: "1px solid transparent",
                  backgroundColor:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #020227;",
                },
                "&.Mui-disabled": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "rgba(255, 255, 255, 0.44)",
                },
              };
            }
            return {
              color: "#1D42C9",
              backgroundColor: "#EDF0FC",
              border: "1px solid #BCC8F5",
              "&:hover": {
                border: "1px solid #8CA2F2",
                backgroundColor: "#DCE4FE",
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                color: "rgba(0, 0, 0, 0.35)",
              },
            };
          }
          if (ownerState.variant === "text") {
            if (ownerState.color === "onDark") {
              return {
                "&:hover": {
                  outline: "1px solid rgba(255, 255, 255, 0.24)",
                },
                "&.Mui-disabled": {
                  color: "rgba(255, 255, 255, 0.44)",
                },
              };
            }
            return {
              "&:hover": {
                outline: "1px solid rgba(0, 0, 0, 0.12)",
              },
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.35);",
              },
            };
          }
        },
      },
    },
  },
});
