/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReconciliationCacheUpdate } from '../models/ReconciliationCacheUpdate';
import type { ReconciliationRun } from '../models/ReconciliationRun';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReconciliationRunsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the last reconciliation run
   * @returns ReconciliationRun Success
   * @throws ApiError
   */
  public getLastReconciliationRun(): CancelablePromise<ReconciliationRun> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/reconciliation-runs/last',
    });
  }

  /**
   * Returns the average duration of the last 10 finished reconciliation-cache-updates runs in seconds
   * @returns number Success
   * @throws ApiError
   */
  public getCacheRefreshDuration(): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/reconciliation-cache-updates/avg-duration',
    });
  }

  /**
   * Gets the active cache updates ordered by startedAt
   * @returns ReconciliationCacheUpdate Success
   * @throws ApiError
   */
  public getActiveCacheUpdates(): CancelablePromise<Array<ReconciliationCacheUpdate>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/reconciliation-cache-updates/active',
    });
  }

}
