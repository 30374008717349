/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResolveForFilterDto } from '../models/ResolveForFilterDto';
import type { ResolveTransactionsDto } from '../models/ResolveTransactionsDto';
import type { UnresolveDto } from '../models/UnresolveDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DataMatchAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public unresolve(
    requestBody?: UnresolveDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/unresolve',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public resolveForFilter(
    requestBody?: ResolveForFilterDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/resolve/filter',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public resolveTransactions(
    requestBody?: ResolveTransactionsDto,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/data-match/resolve/transactions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
