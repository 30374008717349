import { Socket, io } from "socket.io-client";
import config from "./config";

export class SocketSession {
  socket: Socket;
  constructor() {
    this.socket = io(config.API_WS_URL, {
      reconnection: true,
      autoConnect: false,
    });
  }
  async connect(accessToken: string) {
    console.log("socket connect called");
    this.socket.auth = { token: accessToken };
    this.socket.connect();

    this.socket.on("connect", () => {
      console.log("socket connected");
    });
    this.socket.on("disconnect", () => {
      console.log("socket disconnected");
    });
    this.socket.on("error", error => {
      console.log("socket error", error);
    });
  }

  on(event: string, callback: (data: any) => void) {
    this.socket.on(event, callback);
  }

  off(event: string, callback: (data: any) => void) {
    this.socket.off(event, callback);
  }

  isConnected() {
    return this.socket.connected;
  }

  disconnect() {
    this.socket.disconnect();
    this.socket.off("connect");
    this.socket.off("disconnect");
    this.socket.off("error");
  }
}
