import config from "./config";

import { errorLoggingAPI } from "./errorLogging";
import { ApiClient } from "../spec/editor";
import { ApiClientBO } from "../spec/bo";

export const apiClient = new ApiClient({
  BASE: config.API_URL_SHORT,
  VERSION: config.API_VERSION,
});
export const boApiClient = new ApiClientBO({
  BASE: config.API_URL_BACKOFFICE,
  VERSION: config.API_VERSION_BACKOFFICE,
});

export type DefaultApiExceptionResponse = {
  statusCode: number;
  message: string;
};

export class DefaultApiError extends Error {
  constructor(readonly res: DefaultApiExceptionResponse) {
    super(res.message);
    Object.setPrototypeOf(this, DefaultApiError.prototype);
  }
}

export enum ApiExceptionErrorCode {
  DATA_FETCH_ERROR = "data_fetch_error",
}

export type ApiExceptionResponse = {
  statusCode: number;
  message: string;
  error_code: ApiExceptionErrorCode;
  node_uuid?: string;
};

export class ApiError extends Error {
  constructor(readonly res: ApiExceptionResponse) {
    super(res.message);
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export class NetworkError extends Error {
  constructor(message) {
    super(message);
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}

const parseResult = async result => {
  const body = await result.clone().text();
  if (!body) {
    return null;
  }
  const json = await result.json();
  if (!result.ok) {
    console.log({ status: result.status, message: json.message });
    throw new NetworkError(json.message);
  }
  return json;
};

const fetchWrapper = async (url, init, sentryErrorHeading) => {
  try {
    const response = await fetch(url, init);
    return await parseResult(response);
  } catch (e) {
    if (init?.signal?.aborted) throw new DOMException(e.message, "AbortError");
    const { headers, ...rest } = init;
    errorLoggingAPI(sentryErrorHeading, e, rest);
    throw new Error(e.message);
  }
};

const parseResultWithException = async result => {
  const body = await result.clone().text();
  if (!body) {
    return null;
  }
  const json = await result.json();
  if (!result.ok) {
    // if includes "error_code", it's a custom error
    if ("error_code" in json) {
      console.log({ status: result.status, body: json });
      throw new ApiError(json);
    } else {
      throw new DefaultApiError(json);
    }
  }
  return json;
};

const fetchWrapperWithException = async (url, init, sentryErrorHeading) => {
  try {
    const response = await fetch(url, init);
    return await parseResultWithException(response);
  } catch (e) {
    const { body } = init;
    errorLoggingAPI(sentryErrorHeading, e, body);
    if (e instanceof ApiError || e instanceof DefaultApiError) {
      throw e;
    } else {
      throw new NetworkError(e.message);
    }
  }
};

const buildHeaders = accessToken => {
  const headers = { "Content-Type": "application/json" };

  if (accessToken) {
    return {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }
  return headers;
};

export async function uploadFile(accessToken, data) {
  const init = {
    method: "POST",
    headers: { Authorization: `Bearer ${accessToken}` },
    body: data,
  };
  const url = `${config.API_URL}/files`;
  return await fetchWrapper(url, init, "API uploadFile");
}

export async function uploadIntegrationFile(accessToken, data) {
  const init = {
    method: "POST",
    headers: { Authorization: `Bearer ${accessToken}` },
    body: data,
  };
  const url = `${config.API_URL}/files/integration`;
  return await fetchWrapper(url, init, "API uploadIntegrationFile");
}
export async function updateFileUpload(accessToken, fileId, data) {
  const init = {
    method: "PUT",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(data),
  };
  const url = `${config.API_URL}/files/file/${fileId}`;
  return await fetchWrapper(url, init, "API updateFileUpload");
}

export async function dowloadAllSettlements(
  accessToken,
  start_date,
  end_date,
  projectUuids = [],
  source_type = null
) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
  };

  const paramList = {
    start_date: start_date,
    end_date: end_date,
  };
  if (projectUuids.length > 0) {
    paramList["project_uuids"] = projectUuids.join(",");
  }
  if (source_type) {
    paramList["source_type"] = source_type;
  }

  const params = new URLSearchParams(paramList);

  const requestUrl = `${config.API_URL}/settlements/download?${params.toString()}`;
  const response = await fetch(requestUrl, init);

  if (response.status === 404) {
    throw new Error("No files found");
  }
  const blob = await response.blob();
  return blob;
}

export async function getProjectsExport(accessToken, abortController?: AbortController) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
    ...(abortController && { signal: abortController.signal }),
  };
  const url = `${config.API_URL}/dashboard/projects/differencetable/file`;
  const response = await fetch(url, init);
  const blob = await response.blob();
  return blob;
}

export async function getProjectsMachineExport(
  projectUuid,
  accessToken,
  abortController?: AbortController
) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
    ...(abortController && { signal: abortController.signal }),
  };
  const url = `${config.API_URL}/dashboard/projects/${projectUuid}/differencetable/machine-file`;
  const response = await fetch(url, init);
  const blob = await response.blob();
  return blob;
}

export async function getDashboardExport(
  projectUuid,
  accessToken,
  abortController?: AbortController
) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
    ...(abortController && { signal: abortController.signal }),
  };
  const url = `${config.API_URL}/dashboard/projects/${projectUuid}/differencetable/file`;
  const response = await fetch(url, init);
  const blob = await response.blob();
  return blob;
}

export async function getDataMatchingTransactions(
  accessToken,
  limit: number = 100,
  offset: number = 100,
  projects: string[] = [],
  filter = "matched",
  id: string = "",
  uploads: string[] = [],
  statuses: string[] = [],
  methods: string[] = [],
  brandKeys: string[] = [],
  period_start: Date | null = null,
  period_end: Date | null = null,
  abortController?: AbortController
) {
  const init = {
    headers: buildHeaders(accessToken),
    ...(abortController && { signal: abortController.signal }),
  };
  var paramList = {};
  paramList["filter"] = filter;
  paramList["offset"] = offset.toString();
  paramList["limit"] = limit.toString();
  if (projects.length > 0) {
    paramList["projects"] = projects.join(",");
  }
  if (uploads.length > 0) {
    paramList["uploads"] = uploads.join(",");
  }
  if (id) {
    paramList["id"] = id;
  }
  if (period_start) {
    paramList["period_start"] = period_start.toISOString();
  }
  if (period_end) {
    paramList["period_end"] = period_end.toISOString();
  }
  if (statuses.length > 0) {
    const psp = statuses
      .map(status => {
        if (status.includes("psp.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    const pbo = statuses
      .map(status => {
        if (status.includes("pbo.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    if (psp.length > 0) {
      paramList["psp_status"] = psp.join(",");
    }
    if (pbo.length > 0) {
      paramList["pbo_status"] = pbo.join(",");
    }
  }
  if (methods.length > 0) {
    paramList["pbo_method"] = methods.join(",");
  }
  if (brandKeys.length > 0) {
    paramList["brand_keys"] = brandKeys.join(",");
  }
  const params = new URLSearchParams(paramList);
  const url = `${config.API_URL}/data-match?${params.toString()}`;
  return fetchWrapper(url, init, "API data match transactions");
}

export async function getDataMatchingSummary(
  accessToken,
  projects: string[] = [],
  id: string = "",
  uploads: string[] = [],
  statuses: string[] = [],
  methods: string[] = [],
  brandKeys: string[] = [],
  period_start: Date | null = null,
  period_end: Date | null = null,
  abortController?: AbortController
) {
  const init = {
    headers: buildHeaders(accessToken),
    ...(abortController && { signal: abortController.signal }),
  };
  var paramList = {};
  if (projects.length > 0) {
    paramList["projects"] = projects.join(",");
  }
  if (uploads.length > 0) {
    paramList["uploads"] = uploads.join(",");
  }
  if (id) {
    paramList["id"] = id;
  }
  if (period_start) {
    paramList["period_start"] = period_start.toISOString();
  }
  if (period_end) {
    paramList["period_end"] = period_end.toISOString();
  }
  if (statuses.length > 0) {
    const psp = statuses
      .map(status => {
        if (status.includes("psp.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    const pbo = statuses
      .map(status => {
        if (status.includes("pbo.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    if (psp.length > 0) {
      paramList["psp_status"] = psp.join(",");
    }
    if (pbo.length > 0) {
      paramList["pbo_status"] = pbo.join(",");
    }
  }
  if (methods.length > 0) {
    paramList["pbo_method"] = methods.join(",");
  }
  if (brandKeys.length > 0) {
    paramList["brand_keys"] = brandKeys.join(",");
  }
  const params = new URLSearchParams(paramList);
  const url = `${config.API_URL}/data-match/summary?${params.toString()}`;
  return fetchWrapper(url, init, "API get data match summary");
}

export async function getUploadedFile(uuid, accessToken) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
  };

  const requestUrl = `${config.API_URL}/files/download/${uuid}`;

  return await fetchWrapper(requestUrl, init, "API getUploadedFile");
}

export async function datamatchResolveUpsert(data, accessToken) {
  const init = {
    method: "POST",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(data),
  };
  const url = `${config.API_URL}/data-match/resolve`;
  return await fetchWrapper(url, init, "API datamatchResolveCreate");
}

export async function datamatchResolveDelete(data, accesToken) {
  const init = {
    method: "POST",
    headers: buildHeaders(accesToken),
    body: JSON.stringify(data),
  };
  const url = `${config.API_URL}/data-match/unresolve`;
  return await fetchWrapper(url, init, "API datamatchResolveDelete");
}

export async function datamatchDownload(
  accessToken,
  projects: string[] = [],
  filter = "matched",
  id: string = "",
  uploads: string[] = [],
  statuses: string[] = [],
  brandKeys: string[] = [],
  period_start: Date | null = null,
  period_end: Date | null = null,
  methods: string[] = [],
  type: string = "single"
) {
  const init = {
    headers: buildHeaders(accessToken),
  };
  var paramList = {};
  paramList["type"] = type;
  paramList["filter"] = filter;
  if (projects.length > 0) {
    paramList["projects"] = projects.join(",");
  }
  if (uploads.length > 0) {
    paramList["uploads"] = uploads.join(",");
  }
  if (id) {
    paramList["id"] = id;
  }
  if (period_start) {
    paramList["period_start"] = period_start.toISOString();
  }
  if (period_end) {
    paramList["period_end"] = period_end.toISOString();
  }
  if (statuses.length > 0) {
    const psp = statuses
      .map(status => {
        if (status.includes("psp.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    const pbo = statuses
      .map(status => {
        if (status.includes("pbo.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    if (psp.length > 0) {
      paramList["psp_status"] = psp.join(",");
    }
    if (pbo.length > 0) {
      paramList["pbo_status"] = pbo.join(",");
    }
  }
  if (methods.length > 0) {
    paramList["pbo_method"] = methods.join(",");
  }
  if (brandKeys.length > 0) {
    paramList["brand_keys"] = brandKeys.join(",");
  }
  const params = new URLSearchParams(paramList);
  const url = `${config.API_URL}/data-match/download?${params.toString()}`;

  const response = await fetch(url, init);
  if (!response.ok) {
    throw new Error();
  }
  const blob = await response.blob();
  return blob;
}

export async function getDataMatchFilters(
  accessToken,
  projects: string[] = [],
  filter: string = "matched",
  id: string = "",
  uploads: string[] = [],
  statuses: string[] = [],
  methods: string[] = [],
  brandKeys: string[] = [],
  period_start: Date | null = null,
  period_end: Date | null = null,
  abortController?: AbortController
) {
  const init = {
    headers: buildHeaders(accessToken),
    ...(abortController && { signal: abortController.signal }),
  };
  var paramList = {};
  if (projects.length > 0) {
    paramList["projects"] = projects.join(",");
  }
  if (uploads.length > 0) {
    paramList["uploads"] = uploads.join(",");
  }
  if (id) {
    paramList["id"] = id;
  }
  if (period_start) {
    paramList["period_start"] = period_start.toISOString();
  }
  if (period_end) {
    paramList["period_end"] = period_end.toISOString();
  }
  if (filter) {
    paramList["filter"] = filter;
  }
  if (statuses.length > 0) {
    const psp = statuses
      .map(status => {
        if (status.includes("psp.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    const pbo = statuses
      .map(status => {
        if (status.includes("pbo.")) {
          return status.slice(4);
        }
      })
      .filter(Boolean);
    if (psp.length > 0) {
      paramList["psp_status"] = psp.join(",");
    }
    if (pbo.length > 0) {
      paramList["pbo_status"] = pbo.join(",");
    }
  }
  if (methods.length > 0) {
    paramList["pbo_method"] = methods.join(",");
  }
  if (brandKeys.length > 0) {
    paramList["brand_keys"] = brandKeys.join(",");
  }
  const params = new URLSearchParams(paramList);
  const url = `${config.API_URL}/data-match/filters?${params.toString()}`;
  return fetchWrapper(url, init, "API getDataMatchFilters");
}

export async function downloadLastResults(model_uuid, params, accessToken) {
  const init = {
    method: "POST",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(params),
  };
  const url = `${config.API_URL}/ice-calculate/download/${model_uuid}`;
  const response = await fetch(url, init);
  const blob = await response.blob();
  return blob;
}

export async function getModelSettingsByProject(accessToken, project_uuid: string) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
  };

  const requestUrl = `${config.API_URL}/model-configurations/settings/${project_uuid}`;

  return await fetchWrapper(requestUrl, init, "API getModelSettingsByProject");
}

export async function getModelConfigByProject(accessToken, project_uuid: string) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
  };

  const requestUrl = `${config.API_URL}/model-config/${project_uuid}`;

  return await fetchWrapper(requestUrl, init, "API getModelConfigByModel");
}

export async function updateModelConfigs(accessToken, data) {
  const init = {
    method: "PUT",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(data),
  };

  const requestUrl = `${config.API_URL}/model-config`;

  return await fetchWrapper(requestUrl, init, "API getModelConfigByModel");
}

export async function createModelConfig(accessToken, data) {
  const init = {
    method: "POST",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(data),
  };

  const requestUrl = `${config.API_URL}/model-config`;

  return await fetchWrapper(requestUrl, init, "API createModelConfig");
}

export async function patchModelSettings(
  accessToken,
  model_uuid: string,
  _config: any,
  class_name: string
): Promise<any> {
  const init = {
    method: "PUT",
    headers: buildHeaders(accessToken),
    body: JSON.stringify({
      model_uuid: model_uuid,
      config: _config,
      class_name: class_name,
    }),
  };

  const requestUrl = `${config.API_URL}/model-configurations/settings/${model_uuid}`;
  return await fetchWrapper(requestUrl, init, "API patchModelSettings");
}
export async function updateReconciliationRun(reconciliationRunUuid, body, accessToken) {
  const params = {
    uuid: reconciliationRunUuid,
    ...body,
  };

  const init = {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: buildHeaders(accessToken),
  };
  const url = `${config.MATCHING_API_URL}/reconciliation_runs/`;
  return fetchWrapper(url, init, "API createReconciliationRun");
}

export async function calculate({ model_uuid, definition = undefined }, accessToken) {
  const params = {
    model_uuid,
    definition,
  };
  const init = {
    method: "POST",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(params),
  };
  const url = `${config.API_URL}/ice-calculate`;

  return fetchWrapperWithException(url, init, "API calculate");
}

export async function calculateStatus(calculationUuid, accessToken) {
  const init = {
    method: "GET",
    headers: buildHeaders(accessToken),
  };
  const url = `${config.API_URL}/ice-calculate/status/${calculationUuid}`;
  return fetchWrapper(url, init, "API calculateStatus");
}

export async function calculateResults(calculationUuid, accessToken, params) {
  const init = {
    method: "POST",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(params),
  };
  const url = new URL(`${config.API_URL}/ice-calculate/results/${calculationUuid}`);
  return fetchWrapper(url, init, "API get calculation result");
}

export async function getModelLastResult(model_uuid, accessToken, params) {
  const init = {
    method: "POST",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(params),
  };
  const url = new URL(`${config.API_URL}/ice-calculate/results/model-last/${model_uuid}`);
  return fetchWrapper(url, init, "API get model last result");
}
