/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LookerDTO } from '../models/LookerDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LookerAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param src The path to the Looker
   * @returns LookerDTO Returns a signed url for Looker
   * @throws ApiError
   */
  public getAuth(
    src: string,
  ): CancelablePromise<LookerDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/looker/auth',
      query: {
        'src': src,
      },
    });
  }

}
