import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { apiClient } from "../core/api";
import { ApiClient } from "../spec/editor";
import { accessTokenRequest } from "../core/auth";

export function useApiClient(): ApiClient {
  const [client, setClient] = useState<ApiClient>(apiClient);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const createClient = async () => {
      const token = await getAccessTokenSilently(accessTokenRequest);
      apiClient.request.config.TOKEN = token;
      setClient(apiClient);
    };

    if (isAuthenticated) {
      createClient();
    }
  }, [isAuthenticated]);

  return client;
}
