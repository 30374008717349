/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IceAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any
   * @throws ApiError
   */
  public execute(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/ice-calculate',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public status(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/ice-calculate/status/{calculation_uuid}',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public result(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/ice-calculate/results/{calculation_uuid}',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public getLastModelResult(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/ice-calculate/results/model-last/{model_uuid}',
    });
  }

  /**
   * @param modelUuid The UUID of the model to download the last result
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public downloadLastResult(
    modelUuid: any,
    requestBody: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/ice-calculate/download/{model_uuid}',
      path: {
        'model_uuid': modelUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
