import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteProjectDialog from "../Dialogs/Project/DeleteProjectDialog";

const MoreMenu = ({ title, moreOptions }) => {
  const { handleDelete, handleEdit, handleDownload, handleAlternativeDownload } = moreOptions;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [deleteMenuOpen, setDeleteMenuOpen] = useState<boolean>(false);
  const [editMenuOpen, setEditMenuOpen] = useState<boolean>(false);
  const anchorRef = useRef(null);
  if (title === " " || title === "Unassigned") return null;
  return (
    <Box sx={{ ml: "auto", mr: 2 }}>
      <IconButton onClick={() => setMenuOpen(!menuOpen)} ref={anchorRef} color="onDark">
        <MoreVertIcon />
      </IconButton>
      <Menu open={menuOpen} anchorEl={anchorRef.current} onClose={() => setMenuOpen(!menuOpen)}>
        {handleDownload && (
          <MenuItem
            data-action="Table export [HeaderProjectMoreMenu]"
            onClick={async () => {
              await handleDownload();
              setMenuOpen(false);
            }}
          >
            <Typography>Table view export</Typography>
          </MenuItem>
        )}
        {handleAlternativeDownload && (
          <MenuItem
            data-action="Export machine-readable [HeaderProjectMoreMenu]"
            onClick={async () => {
              await handleAlternativeDownload();
              setMenuOpen(false);
            }}
          >
            <Typography>Machine-readable format export</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setEditMenuOpen(true);
            setMenuOpen(false);
          }}
        >
          <Typography>Edit name</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteMenuOpen(true);
            setMenuOpen(false);
          }}
        >
          <Typography color="red">Delete</Typography>
        </MenuItem>
      </Menu>
      {deleteMenuOpen && (
        <DeleteProjectDialog
          success={async () => {
            await handleDelete();
            setDeleteMenuOpen(false);
            setMenuOpen(false);
          }}
          cancel={() => {
            setDeleteMenuOpen(false);
            setMenuOpen(false);
          }}
        />
      )}
    </Box>
  );
};

interface IHeaderProject {
  title: string;
  moreOptions: {
    handleDelete: Function;
    handleEdit: Function;
    handleDownload?: Function;
    handleAlternativeDownload?: Function;
  };
}

const HeaderProject = ({ title, moreOptions }: IHeaderProject) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#020227",
        color: "#fff",
        px: 2,
        py: 1.25,
        width: "100%",
        display: "flex",
        alignItems: "center",
        minHeight: "52px",
      }}
    >
      <IconButton
        color="onDark"
        onClick={() => navigate("/app/projects")}
        data-action="Navigate to /app/projects [HeaderProject]"
      >
        <ArrowBackIcon />
      </IconButton>

      <Box sx={{ ml: 1 }}>
        <Typography variant="h6" fontSize="16px">
          {title}
        </Typography>
      </Box>
      <MoreMenu title={title} moreOptions={moreOptions} />
    </Box>
  );
};

export default HeaderProject;
