/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileExtractionAutoConfigurationRunDTO } from './FileExtractionAutoConfigurationRunDTO';

export type FileExtractionRunDTO = {
  uuid: string;
  file_upload_uuid?: string;
  reconciliation_config_uuid?: string;
  file_extraction_config_uuid?: string;
  status?: FileExtractionRunDTO.status;
  type?: FileExtractionRunDTO.type;
  created_at: string;
  updated_at?: string;
  finished_at?: string;
  results?: Record<string, any>;
  file_extraction_auto_configuration_runs?: FileExtractionAutoConfigurationRunDTO;
};

export namespace FileExtractionRunDTO {

  export enum status {
    CREATED = 'created',
    STARTED = 'started',
    COMPLETED = 'completed',
    FAILED = 'failed',
  }

  export enum type {
    SYNC = 'sync',
    FULL = 'full',
  }


}

