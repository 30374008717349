/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateModelConfigDTO } from '../models/CreateModelConfigDTO';
import type { ModelConfigDTO } from '../models/ModelConfigDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModelConfigAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param projectUuid The UUID of the project
   * @returns ModelConfigDTO Returns all model configs
   * @throws ApiError
   */
  public getModelConfigs(
    projectUuid: string,
  ): CancelablePromise<Array<ModelConfigDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/model-config/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param requestBody
   * @returns ModelConfigDTO Returns the updated model configs
   * @throws ApiError
   */
  public updateModelConfigs(
    requestBody: Array<ModelConfigDTO>,
  ): CancelablePromise<ModelConfigDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/model-config',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns ModelConfigDTO Returns the created model config
   * @returns any
   * @throws ApiError
   */
  public createModelConfigs(
    requestBody: CreateModelConfigDTO,
  ): CancelablePromise<ModelConfigDTO | Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/model-config',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
