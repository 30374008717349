/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InternalApiAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any
   * @throws ApiError
   */
  public getProjects(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/internal-api/v1/projects',
    });
  }

  /**
   * @param projectUuid
   * @returns any
   * @throws ApiError
   */
  public getProjectEstimations(
    projectUuid: string,
  ): CancelablePromise<Array<Record<string, any>>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/internal-api/v1/projects/{project_uuid}/estimations',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

}
