/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandDTO } from '../models/BrandDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BrandsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns BrandDTO All brands for org
   * @throws ApiError
   */
  public getBrands(): CancelablePromise<Array<BrandDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/brands',
    });
  }

  /**
   * @param requestBody Updated organization brands
   * @returns BrandDTO
   * @throws ApiError
   */
  public updateBrands(
    requestBody: Array<BrandDTO>,
  ): CancelablePromise<Array<BrandDTO>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/brands',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
