/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentCreateDTO } from '../models/CommentCreateDTO';
import type { CommentDTO } from '../models/CommentDTO';
import type { DashboardCountResponse } from '../models/DashboardCountResponse';
import type { DashboardGraphDTO } from '../models/DashboardGraphDTO';
import type { DifferenceTableDTO } from '../models/DifferenceTableDTO';
import type { InvoiceDTO } from '../models/InvoiceDTO';
import type { InvoiceTableDTO } from '../models/InvoiceTableDTO';
import type { LineMatchingRowUpdateDTO } from '../models/LineMatchingRowUpdateDTO';
import type { LineMatchingTableDTO } from '../models/LineMatchingTableDTO';
import type { OptionsDTO } from '../models/OptionsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DashboardAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param projectUuid Project UUID
   * @returns InvoiceTableDTO Get invoice
   * @throws ApiError
   */
  public getInvoiceTable(
    projectUuid: string,
  ): CancelablePromise<InvoiceTableDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/invoice/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param requestBody Invoice to create
   * @returns InvoiceDTO Returns created invoice with uuid
   * @throws ApiError
   */
  public createInvoice(
    requestBody: InvoiceDTO,
  ): CancelablePromise<InvoiceDTO> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/dashboard/invoice',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param invoiceUuid Invoice UUID
   * @param requestBody Invoice data to update
   * @returns InvoiceDTO Returns updated invoice
   * @throws ApiError
   */
  public updateInvoice(
    invoiceUuid: string,
    requestBody: InvoiceDTO,
  ): CancelablePromise<InvoiceDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/dashboard/invoice/{invoice_uuid}',
      path: {
        'invoice_uuid': invoiceUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param invoiceUuid Invoice UUID
   * @returns DashboardCountResponse Returns number of deleted invoices
   * @throws ApiError
   */
  public deleteInvoice(
    invoiceUuid: string,
  ): CancelablePromise<DashboardCountResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/dashboard/invoice/{invoice_uuid}',
      path: {
        'invoice_uuid': invoiceUuid,
      },
    });
  }

  /**
   * @param projectUuid Project UUID
   * @param requestBody Invoice table row to create
   * @returns DashboardCountResponse Returns number of updated invoices
   * @throws ApiError
   */
  public updateInvoiceLines(
    projectUuid: string,
    requestBody: InvoiceTableDTO,
  ): CancelablePromise<Array<DashboardCountResponse>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/dashboard/invoicelines/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectUuid Project UUID
   * @returns LineMatchingTableDTO Returns linematching data
   * @throws ApiError
   */
  public getLineMatchingTable(
    projectUuid: string,
  ): CancelablePromise<LineMatchingTableDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/linematching/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param projectUuid Project UUID
   * @param requestBody Invoice table row to create
   * @returns DashboardCountResponse
   * @throws ApiError
   */
  public updateLineMatchingTable(
    projectUuid: string,
    requestBody: Array<LineMatchingRowUpdateDTO>,
  ): CancelablePromise<Array<DashboardCountResponse>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/dashboard/linematching/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param dateFromMonths Returns results from the last {queryValue} months
   * @param projectUuid Project UUID
   * @returns DifferenceTableDTO Returns import table data
   * @throws ApiError
   */
  public getDifferenceTable(
    dateFromMonths: number,
    projectUuid: string,
  ): CancelablePromise<DifferenceTableDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/differencetable/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
      query: {
        'dateFromMonths': dateFromMonths,
      },
    });
  }

  /**
   * @param projectUuid Project UUID
   * @param format Export format
   * @returns any Returns the export difference table file in {format} for given project
   * @throws ApiError
   */
  public getDifferenceTableExport(
    projectUuid: string,
    format: string,
  ): CancelablePromise<{
    file?: Blob;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/projects/{project_uuid}/differencetable/file',
      path: {
        'project_uuid': projectUuid,
        'format': format,
      },
    });
  }

  /**
   * @param projectUuid Project UUID
   * @param format Export format
   * @returns any Returns the export difference table file in {format} for machine-readable uses for given project
   * @throws ApiError
   */
  public getDifferenceTableExportMachine(
    projectUuid: string,
    format: string,
  ): CancelablePromise<{
    file?: Blob;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/projects/{project_uuid}/differencetable/machine-file',
      path: {
        'project_uuid': projectUuid,
        'format': format,
      },
    });
  }

  /**
   * @param format Export format
   * @returns binary Returns the export difference table file in {format} for all projects
   * @throws ApiError
   */
  public getAllProjectsExport(
    format: string,
  ): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/projects/differencetable/file',
      path: {
        'format': format,
      },
    });
  }

  /**
   * @param line Returns results that are only of {queryValue} invoice values
   * @param dateFromMonths Returns results from the last {queryValue} months
   * @param projectUuid Project UUID
   * @returns DashboardGraphDTO Returns graph data
   * @throws ApiError
   */
  public getGraph(
    line: any,
    dateFromMonths: number,
    projectUuid: string,
  ): CancelablePromise<DashboardGraphDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/graph/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
      query: {
        'line': line,
        'dateFromMonths': dateFromMonths,
      },
    });
  }

  /**
   * @param projectUuid Project UUID
   * @returns OptionsDTO Returns graph options
   * @throws ApiError
   */
  public getGraphOptions(
    projectUuid: string,
  ): CancelablePromise<OptionsDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/options/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param requestBody Comment to create
   * @returns CommentDTO Returns created comment
   * @returns any
   * @throws ApiError
   */
  public createComment(
    requestBody: CommentCreateDTO,
  ): CancelablePromise<CommentDTO | Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/dashboard/comment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param commentUuid Comment UUID
   * @param requestBody Comment to update
   * @returns CommentDTO Returns updated comment
   * @throws ApiError
   */
  public updateComment(
    commentUuid: string,
    requestBody: CommentCreateDTO,
  ): CancelablePromise<CommentDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/dashboard/comment/{comment_uuid}',
      path: {
        'comment_uuid': commentUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param commentUuid Comment UUID
   * @returns DashboardCountResponse
   * @throws ApiError
   */
  public deleteComment(
    commentUuid: string,
  ): CancelablePromise<DashboardCountResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/dashboard/comment/{comment_uuid}',
      path: {
        'comment_uuid': commentUuid,
      },
    });
  }

  /**
   * @param projectUuid Project UUID
   * @returns CommentDTO Returns comments for dashboard
   * @throws ApiError
   */
  public getComments(
    projectUuid: string,
  ): CancelablePromise<Array<CommentDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/dashboard/comment/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

}
