import { useAuth0 } from "@auth0/auth0-react";
import { Box, capitalize, MenuList, Tooltip } from "@mui/material";
import { useContext } from "react";
import UIContext from "../../context/UIContext";
import {
  AppNavLink,
  Avatar,
  ExternalLink,
  HelpListIcon,
  IntegrationsListItem,
  Logout,
  LogoutListItem,
  LookerDashboardItem,
  LookerExploreItem,
  MatchingListItem,
  ProjectsListItem,
  SettingsListItem,
  SettlementsListItem,
  UploadListItem,
} from "./SidebarElements";

const AppSidebar = () => {
  const auth0 = useAuth0();
  const organization = auth0.user["https://iter8.ai/organization_name"] || "";
  const {
    logout,
    user: { nickname },
  } = auth0;

  const { toolVisibilitySettings } = useContext(UIContext);

  return (
    <Box sx={{ minWidth: "64px", backgroundColor: "primary.dark" }}>
      <Box
        sx={{
          height: "65px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 4,
        }}
      >
        <Tooltip title={capitalize(organization)}>
          <Avatar>{nickname.charAt(0).toUpperCase()}</Avatar>
        </Tooltip>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 97px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MenuList sx={{ color: "onDark" }}>
          <Tooltip title="Upload" placement="right">
            <AppNavLink
              to={"/app/upload"}
              children={({ isActive }) => (
                <UploadListItem $isActive={isActive} data-action="Navigate to /app/upload" />
              )}
            />
          </Tooltip>
          {toolVisibilitySettings?.matching_tool && (
            <Tooltip title="Payments reconciliation" placement="right">
              <AppNavLink
                to={"/app/matching"}
                children={({ isActive }) => (
                  <MatchingListItem $isActive={isActive} data-action="Navigate to /app/matching" />
                )}
              />
            </Tooltip>
          )}
          {toolVisibilitySettings?.looker_explore && (
            <Tooltip title="Explore" placement="right">
              <AppNavLink
                to={"/app/explore"}
                children={({ isActive }) => (
                  <LookerExploreItem $isActive={isActive} data-action="Navigate to /app/explore" />
                )}
              />
            </Tooltip>
          )}
          {toolVisibilitySettings?.looker_dashboard && (
            <Tooltip title="Dashboard" placement="right">
              <AppNavLink
                to={"/app/dashboard"}
                children={({ isActive }) => (
                  <LookerDashboardItem
                    $isActive={isActive}
                    data-action="Navigate to /app/dashboard"
                  />
                )}
              />
            </Tooltip>
          )}
          {toolVisibilitySettings?.fee_analysis && (
            <Tooltip title="Fee estimation" placement="right">
              <AppNavLink
                to={"/app/projects"}
                children={({ isActive }) => (
                  <ProjectsListItem $isActive={isActive} data-action="Navigate to /app/projects" />
                )}
              />
            </Tooltip>
          )}

          {toolVisibilitySettings?.settlement_tool && (
            <Tooltip title="Settlements" placement="right">
              <AppNavLink
                to={"/app/settlement"}
                children={({ isActive }) => (
                  <SettlementsListItem
                    $isActive={isActive}
                    data-action="Navigate to /app/settlement"
                  />
                )}
              />
            </Tooltip>
          )}
        </MenuList>
        <MenuList sx={{ color: "onDark", p: 0 }}>
          <Tooltip title="Settings" placement="right">
            <AppNavLink
              to={"/app/settings"}
              children={({ isActive }) => (
                <SettingsListItem $isActive={isActive} data-action="Navigate to /app/settings" />
              )}
            />
          </Tooltip>
          <Tooltip title="Integrations" placement="right">
            <AppNavLink
              to={"/app/integrations"}
              children={({ isActive }) => (
                <IntegrationsListItem
                  $isActive={isActive}
                  data-action="Navigate to /app/integrations"
                />
              )}
            />
          </Tooltip>
          <Tooltip title="Knowledge base" placement="right">
            <ExternalLink
              href="http://knowledge-base.reiterate.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <HelpListIcon data-action="Help" />
            </ExternalLink>
          </Tooltip>

          <Tooltip title="Log out" placement="right">
            <Logout onClick={() => logout({ returnTo: window.location.origin + "/login" })}>
              <LogoutListItem data-action="Navigate to /logout" />
            </Logout>
          </Tooltip>
        </MenuList>
      </Box>
    </Box>
  );
};

export default AppSidebar;
