/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransactionGroupDTO } from '../models/TransactionGroupDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TransactionGroupsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns TransactionGroupDTO Returns all transaction groups
   * @throws ApiError
   */
  public getTransactionGroups(): CancelablePromise<Array<TransactionGroupDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/transaction-groups',
    });
  }

  /**
   * @param transactionGroupUuid
   * @param requestBody
   * @returns TransactionGroupDTO Returns the updated transaction group
   * @throws ApiError
   */
  public updateTransactionGroup(
    transactionGroupUuid: string,
    requestBody: TransactionGroupDTO,
  ): CancelablePromise<TransactionGroupDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/transaction-groups/{transaction_group_uuid}',
      path: {
        'transaction_group_uuid': transactionGroupUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
