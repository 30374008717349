/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileExtractionConfigsDTO } from '../models/FileExtractionConfigsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExtractionsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param id FileExtractionConfig UUID
   * @param requestBody FileExtractionConfig data to be updated
   * @returns any
   * @throws ApiError
   */
  public updateFileExtractionConfig(
    id: string,
    requestBody: FileExtractionConfigsDTO,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/extractions/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public deleteFileExtractionConfig(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/extractions/{id}',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public getFileExtractionConfigs(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/extractions',
    });
  }

}
