/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectDTO } from '../models/CreateProjectDTO';
import type { LatestSyncDTO } from '../models/LatestSyncDTO';
import type { ModelDTO } from '../models/ModelDTO';
import type { OverviewDTO } from '../models/OverviewDTO';
import type { OverviewOptions } from '../models/OverviewOptions';
import type { ProjectDTO } from '../models/ProjectDTO';
import type { SearchOptions } from '../models/SearchOptions';
import type { UpdateProjectDTO } from '../models/UpdateProjectDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProjectsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns ProjectDTO Returns the created project
   * @throws ApiError
   */
  public createProject(
    requestBody: CreateProjectDTO,
  ): CancelablePromise<ProjectDTO> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/projects',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProjectDTO Returns the created project
   * @throws ApiError
   */
  public getProjects(): CancelablePromise<Array<ProjectDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/projects',
    });
  }

  /**
   * @param projectId The UUID of the project to get
   * @returns ProjectDTO Returns the requested project with included models
   * @throws ApiError
   */
  public getProjectById(
    projectId: string,
  ): CancelablePromise<ProjectDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/projects/{project_id}',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * @param projectUuid The UUID of the project to update
   * @param requestBody
   * @returns ProjectDTO Returns the created project
   * @throws ApiError
   */
  public updateProject(
    projectUuid: any,
    requestBody: UpdateProjectDTO,
  ): CancelablePromise<ProjectDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/projects/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param projectUuid The UUID of the project to delete
   * @returns ProjectDTO Returns the deleted project
   * @throws ApiError
   */
  public deleteProject(
    projectUuid: any,
  ): CancelablePromise<ProjectDTO> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/projects/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param requestBody
   * @returns ModelDTO Returns the searched models
   * @throws ApiError
   */
  public searchModels(
    requestBody: SearchOptions,
  ): CancelablePromise<Array<ModelDTO>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/projects/search/models',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns ProjectDTO Returns the searched projects
   * @throws ApiError
   */
  public searchProjects(
    requestBody: SearchOptions,
  ): CancelablePromise<Array<ProjectDTO>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/projects/search/projects',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns LatestSyncDTO Returns the latest sync date
   * @throws ApiError
   */
  public getLatestSync(): CancelablePromise<LatestSyncDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/projects/sync/latest',
    });
  }

  /**
   * @param requestBody
   * @returns OverviewDTO Returns the overview data across all projects for the given period
   * @throws ApiError
   */
  public getOverview(
    requestBody: OverviewOptions,
  ): CancelablePromise<Array<OverviewDTO>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/projects/overview',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
