import * as Sentry from "@sentry/browser";

export const errorLoggingAPI = (name, error, body = null) => {
  Sentry.withScope(scope => {
    scope.setLevel("error");
    const hasBody = Object.entries(body).length !== 0;
    if (hasBody) {
      Sentry.setExtra("body", body);
    }
    Sentry.setExtra("error", error.stack);
    Sentry.captureMessage(name);
  });
};
