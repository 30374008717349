/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { BrandsAPI } from './services/BrandsAPI';
import { ConnectionsAPI } from './services/ConnectionsAPI';
import { CurrencyConverterAPI } from './services/CurrencyConverterAPI';
import { DashboardAPI } from './services/DashboardAPI';
import { DataMatchAPI } from './services/DataMatchAPI';
import { DefaultAPI } from './services/DefaultAPI';
import { ExtractionsAPI } from './services/ExtractionsAPI';
import { FilesAPI } from './services/FilesAPI';
import { GoogleAPI } from './services/GoogleAPI';
import { IceAPI } from './services/IceAPI';
import { InternalApiAPI } from './services/InternalApiAPI';
import { LookerAPI } from './services/LookerAPI';
import { ModelConfigAPI } from './services/ModelConfigAPI';
import { ModelConfigurationsAPI } from './services/ModelConfigurationsAPI';
import { ModelsAPI } from './services/ModelsAPI';
import { ProjectsAPI } from './services/ProjectsAPI';
import { SettingsAPI } from './services/SettingsAPI';
import { SettlementsAPI } from './services/SettlementsAPI';
import { TransactionGroupsAPI } from './services/TransactionGroupsAPI';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

  public readonly brands: BrandsAPI;
  public readonly connections: ConnectionsAPI;
  public readonly currencyConverter: CurrencyConverterAPI;
  public readonly dashboard: DashboardAPI;
  public readonly dataMatch: DataMatchAPI;
  public readonly default: DefaultAPI;
  public readonly extractions: ExtractionsAPI;
  public readonly files: FilesAPI;
  public readonly google: GoogleAPI;
  public readonly ice: IceAPI;
  public readonly internalApi: InternalApiAPI;
  public readonly looker: LookerAPI;
  public readonly modelConfig: ModelConfigAPI;
  public readonly modelConfigurations: ModelConfigurationsAPI;
  public readonly models: ModelsAPI;
  public readonly projects: ProjectsAPI;
  public readonly settings: SettingsAPI;
  public readonly settlements: SettlementsAPI;
  public readonly transactionGroups: TransactionGroupsAPI;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.brands = new BrandsAPI(this.request);
    this.connections = new ConnectionsAPI(this.request);
    this.currencyConverter = new CurrencyConverterAPI(this.request);
    this.dashboard = new DashboardAPI(this.request);
    this.dataMatch = new DataMatchAPI(this.request);
    this.default = new DefaultAPI(this.request);
    this.extractions = new ExtractionsAPI(this.request);
    this.files = new FilesAPI(this.request);
    this.google = new GoogleAPI(this.request);
    this.ice = new IceAPI(this.request);
    this.internalApi = new InternalApiAPI(this.request);
    this.looker = new LookerAPI(this.request);
    this.modelConfig = new ModelConfigAPI(this.request);
    this.modelConfigurations = new ModelConfigurationsAPI(this.request);
    this.models = new ModelsAPI(this.request);
    this.projects = new ProjectsAPI(this.request);
    this.settings = new SettingsAPI(this.request);
    this.settlements = new SettlementsAPI(this.request);
    this.transactionGroups = new TransactionGroupsAPI(this.request);
  }
}

