import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { ListContainer, ListItem, ProjectNavLink } from "./SidebarElements";

const ProjectSidebar = () => {
  const { projectUuid } = useParams();
  const isUnassigned = projectUuid === "unassigned";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "210px",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <ListContainer>
        <ProjectNavLink
          to={`/app/projects/${projectUuid}/dashboard`}
          children={({ isActive }) => (
            <ListItem $isActive={isActive} $isUnassigned={isUnassigned}>
              <Typography
                fontSize="13px"
                fontWeight="700"
                data-action="Navigate to /app/projects/:projectUuid/dashboard"
              >
                Dashboard
              </Typography>
            </ListItem>
          )}
        />

        <ProjectNavLink
          to={`/app/projects/${projectUuid}/estimation`}
          children={({ isActive }) => (
            <ListItem $isActive={isActive}>
              <Typography
                fontSize="13px"
                fontWeight="700"
                data-action="Navigate to /app/projects/:projectUuid/estimation"
              >
                Estimation
              </Typography>
            </ListItem>
          )}
        />
        <ProjectNavLink
          to={`/app/projects/${projectUuid}/models`}
          children={({ isActive }) => (
            <ListItem $isActive={isActive} $isUnassigned={isUnassigned}>
              <Typography
                fontSize="13px"
                fontWeight="700"
                data-action="Navigate to /app/projects/:projectUuid/models"
              >
                Model settings
              </Typography>
            </ListItem>
          )}
        />
        <ProjectNavLink
          to={`/app/projects/${projectUuid}/vendor-statements`}
          children={({ isActive }) => (
            <ListItem $isActive={isActive} $isUnassigned={isUnassigned}>
              <Typography
                fontSize="13px"
                fontWeight="700"
                data-action="Navigate to /app/projects/:projectUuid/vendor-statements"
              >
                Vendor statements
              </Typography>
            </ListItem>
          )}
        />
        <ProjectNavLink
          to={`/app/projects/${projectUuid}/linematching`}
          children={({ isActive }) => (
            <ListItem $isActive={isActive} $isUnassigned={isUnassigned}>
              <Typography
                fontSize="13px"
                fontWeight="700"
                data-action="Navigate to /app/projects/:projectUuid/linematching"
              >
                Line matching
              </Typography>
            </ListItem>
          )}
        />
      </ListContainer>
    </Box>
  );
};

export default ProjectSidebar;
