/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectionDTO } from '../models/ConnectionDTO';
import type { CreateConnectionDTO } from '../models/CreateConnectionDTO';
import type { TestPIQConnectionDTO } from '../models/TestPIQConnectionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConnectionsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param type Connection type enum
   * @returns ConnectionDTO
   * @throws ApiError
   */
  public getConnections(
    type?: string,
  ): CancelablePromise<Array<ConnectionDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/connections',
      query: {
        'type': type,
      },
    });
  }

  /**
   * @param requestBody
   * @returns ConnectionDTO
   * @throws ApiError
   */
  public addConnection(
    requestBody: CreateConnectionDTO,
  ): CancelablePromise<ConnectionDTO> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/connections',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param connectionUuid Connection UUID
   * @returns ConnectionDTO
   * @throws ApiError
   */
  public getConnection(
    connectionUuid: string,
  ): CancelablePromise<ConnectionDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/connections/{connection_uuid}',
      path: {
        'connection_uuid': connectionUuid,
      },
    });
  }

  /**
   * @param connectionUuid Connection UUID
   * @param requestBody
   * @returns ConnectionDTO
   * @throws ApiError
   */
  public updateConnection(
    connectionUuid: string,
    requestBody: ConnectionDTO,
  ): CancelablePromise<ConnectionDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/connections/{connection_uuid}',
      path: {
        'connection_uuid': connectionUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public deleteResource(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/connections/{connection_uuid}',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public encrypt(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/connections/encrypt/{id}',
    });
  }

  /**
   * @param requestBody
   * @returns boolean Connection successful
   * @throws ApiError
   */
  public testConnection(
    requestBody: TestPIQConnectionDTO,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/connections/paymentiq-test',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
