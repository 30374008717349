/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateSettlementDTO } from '../models/GenerateSettlementDTO';
import type { SettlementDTO } from '../models/SettlementDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SettlementsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns any Publishes a queue message to generate settlement statements
   * @throws ApiError
   */
  public generateSettlementStatements(
    requestBody: GenerateSettlementDTO,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/settlements',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param startDate
   * @param endDate
   * @returns SettlementDTO Returns all generated settlements within the queried date range
   * @throws ApiError
   */
  public getAllSettlement(
    startDate?: string,
    endDate?: string,
  ): CancelablePromise<Array<SettlementDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/settlements',
      query: {
        'start_date': startDate,
        'end_date': endDate,
      },
    });
  }

  /**
   * @param startDate
   * @param endDate
   * @param sourceType
   * @param projectUuids
   * @returns any
   * @throws ApiError
   */
  public downloadSettlementStatement(
    startDate: string,
    endDate: string,
    sourceType: string,
    projectUuids?: Array<string>,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/settlements/download',
      query: {
        'start_date': startDate,
        'end_date': endDate,
        'project_uuids': projectUuids,
        'source_type': sourceType,
      },
    });
  }

}
