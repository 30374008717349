/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteFileUploadDTO } from '../models/DeleteFileUploadDTO';
import type { FileDownloadDTO } from '../models/FileDownloadDTO';
import type { FileUploadDTO } from '../models/FileUploadDTO';
import type { FileUploadSummaryDTO } from '../models/FileUploadSummaryDTO';
import type { RelevantFileDTO } from '../models/RelevantFileDTO';
import type { UpdateFileUploadDTO } from '../models/UpdateFileUploadDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FilesAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns FileUploadDTO
   * @throws ApiError
   */
  public uploadFiles(): CancelablePromise<Array<FileUploadDTO>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/files',
    });
  }

  /**
   * @param periods
   * @param projectUuid
   * @returns FileUploadDTO Returns files from {periods} and {project_uuid}
   * @throws ApiError
   */
  public getFiles(
    periods?: string,
    projectUuid?: string,
  ): CancelablePromise<Array<FileUploadDTO>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files',
      query: {
        'periods': periods,
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @returns FileUploadDTO
   * @throws ApiError
   */
  public uploadIntegrationFiles(): CancelablePromise<Array<FileUploadDTO>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/files/integration',
    });
  }

  /**
   * @param fileUuid
   * @param requestBody
   * @returns FileUploadDTO Returns the updated file
   * @throws ApiError
   */
  public updateFile(
    fileUuid: string,
    requestBody: UpdateFileUploadDTO,
  ): CancelablePromise<FileUploadDTO> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/files/file/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param fileUuid
   * @returns DeleteFileUploadDTO
   * @throws ApiError
   */
  public deleteFile(
    fileUuid: string,
  ): CancelablePromise<DeleteFileUploadDTO> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/files/file/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
    });
  }

  /**
   * @param fileUuid
   * @returns FileUploadDTO
   * @throws ApiError
   */
  public getFile(
    fileUuid: string,
  ): CancelablePromise<FileUploadDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/file/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
    });
  }

  /**
   * @returns RelevantFileDTO Returns all relevant (7 days successful and failed) files
   * @throws ApiError
   */
  public getRelevantFiles(): CancelablePromise<RelevantFileDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/relevant',
    });
  }

  /**
   * @returns FileUploadSummaryDTO Returns a summary of the files in months format
   * @throws ApiError
   */
  public getSummary(): CancelablePromise<FileUploadSummaryDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/summary',
    });
  }

  /**
   * @param fileUuid The UUID of the file to download
   * @returns FileDownloadDTO Returns the S3 signed URL to download the file from
   * @throws ApiError
   */
  public getDownloadedFile(
    fileUuid: string,
  ): CancelablePromise<FileDownloadDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/download/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
    });
  }

}
