/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CurrencyConverterAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param date Date
   * @param to Target currency
   * @param from Base currency
   * @returns number
   * @throws ApiError
   */
  public getCurrencyRates(
    date: string,
    to: string,
    from: string,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/converter',
      query: {
        'date': date,
        'to': to,
        'from': from,
      },
    });
  }

}
