/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModelSettingsDTO } from '../models/ModelSettingsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ModelConfigurationsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns any
   * @throws ApiError
   */
  public getModelTemplates(): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/model-configurations/templates',
    });
  }

  /**
   * @param projectUuid The UUID of the model to get
   * @returns ModelSettingsDTO Returns the model settings for the given model
   * @throws ApiError
   */
  public getModelSettingsByProject(
    projectUuid: string,
  ): CancelablePromise<ModelSettingsDTO> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/model-configurations/settings/{project_uuid}',
      path: {
        'project_uuid': projectUuid,
      },
    });
  }

  /**
   * @param modelUuid
   * @returns any
   * @throws ApiError
   */
  public patchModelSettings(
    modelUuid: string,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/model-configurations/settings/{model_uuid}',
      path: {
        'model_uuid': modelUuid,
      },
    });
  }

}
